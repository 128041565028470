<!-- rakumart QA -->
<template>
  <div>
    <ohead />
    <div class="pageBody">
      <searchModel />
      <div class="don">
        <!-- <div v-html="showpage.title"></div>
        <div v-html="showpage.answer"></div> -->
        <template v-if="showpage.menuLevel == 1">
          <div v-html="showpage.content"></div>
          <!-- <div id="dd"></div> -->
        </template>
        <template v-if="showpage.menuLevel == 2">
          <!-- <div v-html="showpage.content"></div> -->
          <div class="secconBox">
            <div class="titleBox">
              <span
                class="pageNameSpan"
                @click="$fun.routerToPage('/centro-de-ayuda')"
                >Centro de ayuda</span
              >
              <i class="el-icon-arrow-right"></i>
              <span class="blurFont">{{ showpage.menu }}</span>
            </div>
            <div class="BodyBox">
              <leftMenu :actMenu="showpage.menu" />
              <div class="rightBox">
                <div class="showBodyBoxTitle">
                  {{ showpage.title }}
                </div>
                <div class="showBodyBoxAnswer">
                  {{ showpage.answer }}
                </div>
                <hr />
                <div v-html="showpage.content"></div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="showpage.menuLevel == 3">
          <div class="secconBox">
            <div class="titleBox">
              <span
                class="pageNameSpan"
                @click="$fun.routerToPage('/centro-de-ayuda')"
                >Centro de ayuda</span
              >
              <i class="el-icon-arrow-right"></i>
              <span class="blurFont">{{ showpage.menu }}</span>
              <i class="el-icon-arrow-right"></i>
              <span class="blurFont">{{ showpage.pageName }}</span>
            </div>
            <div class="BodyBox">
              <leftMenu :actMenu="showpage.menu" />
              <div class="rightBox thred">
                <div v-html="showpage.title"></div>
                <div v-html="showpage.answer" class="titleFontThree"></div>
                <div class="dianzi"></div>
                <!-- class="listFont numStyle" allFontPage -->

                <div v-html="showpage.content"></div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <contactUs />
    <ofooter />
  </div>
</template>
<script>
import ohead from "@/components/head/HomePageTop.vue";
import searchModel from "./components/searchModel.vue";
import ofooter from "@/components/foot/Foot.vue";
import pageData from "./unitl/pageData";
import leftMenu from "./components/leftMenu.vue";
import contactUs from "./components/contactUs.vue";
export default {
  data() {
    return {
      pageData,
    };
  },
  components: {
    ohead,
    searchModel,
    contactUs,
    ofooter,
    leftMenu,
  },
  computed: {
    showpage() {
      return pageData.find((item) => {
        return item.router == this.$route.name;
      });
    },
  },
  created() {},
  mounted() {
    let divArr = document.getElementsByName("myClick");
    divArr.forEach((divItem) => {
      divItem.addEventListener("click", () => {
        let _this = this;
        return eval("_this." + divItem.getAttribute("@click"));
      });
    });
  },
  methods: {
    show(v) {
      console.log(v);
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
@import "./css/pagecss.scss";
.blurFont {
  color: #1a289d;
}
.pageBody {
  .don {
    background-color: white;
    padding-bottom: 1px;
    .secconBox {
      width: 1000px;
      margin: 0 auto;
      .titleBox {
        padding: 30px 10px 24px;
        font-size: 14px;
        .pageNameSpan {
          color: #7a7a7a;
          cursor: pointer;
        }
        .el-icon-arrow-right {
          margin: 0 10px;
          color: #1a289d;
        }
      }
      .BodyBox {
        margin: 20px 0 80px;
        display: flex;
        align-items: flex-start;
        hr {
          margin: 20px 0;
          background-color: #d5d5d5;
          width: 100%;
        }
        .dianzi {
          height: 14px;
        }
      }
      .rightBox {
        flex: 1;

        padding: 10px;
      }
      .rightBox.thred {
        max-width: 738px;
      }
      /deep/.showBodyBoxTitle {
        font-size: 26px;
        font-weight: 500;
        line-height: 1.4em;
        margin-bottom: 20px;
      }
      /deep/.titleFontThree {
        .showBodyBoxAnswer {
          font-size: 15px;
          font-weight: 400;
          line-height: 2em;
        }
      }
      /deep/.showBodyBoxAnswer {
        font-size: 14px;
        line-height: 1.5;
        color: #404040;
      }
    }
  }
}
</style>
